import { defineStore } from 'pinia';
import api from '@/services/api';
import { mapArrayToObjectsByKey } from '@/helpers/functions';
import { defaultLanderAnalytics } from '@/helpers/analytics';
import { mapLander } from '@/helpers/lander';
import { useDashboardStore } from './dashboard';
import { useReportingStore } from './reporting';

export const useLandingPagesStore = defineStore('landingPages', {
  state: () => ({
    landers: [],
    loaded: false,
  }),
  getters: {
    landersById: (state) => mapArrayToObjectsByKey(state.landers, 'id'),
    // landersByFolderId: (state) => state.landers.reduce((item, acc) => {
    //   if (!acc[item.folderId]) acc[item.folderId] = [];
    //   acc[item.folderId].push(item);
    //   return acc;
    // }, {})
  },
  actions: {
    async getLandingPages(force) {
      if (!this.loaded || force) {
        try {
          const { landers } = await api.landingPages.getAll();
          this.landers = landers.map((lander) => mapLander(lander));
          this.loaded = true;
        } catch (err) {
          console.log(err);
        }
      }
    },
    async createLandingPage(payload, type = 'create') {
      const dashboardStore = useDashboardStore();
      const reportingStore = useReportingStore();

      if (type === 'clone') {
        const { id, newLander } = payload;
        const index = this.landers.findIndex((item) => item.id === id);
        if (index > -1) {
          dashboardStore.addPerformanceAnalytics({ id: newLander.id, ...defaultLanderAnalytics });
          reportingStore.addPerformanceAnalytics({ id: newLander.id, ...defaultLanderAnalytics });
          this.landers.splice(index, 0, mapLander(newLander));
        }
      } else {
        dashboardStore.addPerformanceAnalytics({ id: payload.id, ...defaultLanderAnalytics });
        reportingStore.addPerformanceAnalytics({ id: payload.id, ...defaultLanderAnalytics });
        this.landers.unshift(mapLander(payload));
      }
    },
    async updateLandingPage(payload) {
      const index = this.landers.findIndex((item) => item.id === payload.id);
      if (index > -1) this.landers.splice(index, 1, mapLander(payload));
    },
    async deleteLandingPage(id) {
      const index = this.landers.findIndex((item) => item.id === id);
      if (index > -1) this.landers.splice(index, 1);
    },
    clearStore() {
      this.$reset();
    },
  },
});
