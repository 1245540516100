import moment from 'moment';
import session from '@/services/session';

const FORMAT = 'YYYY-MM-DD';
let instance = null;

class DateRangePresets {
  constructor() {
    this.ranges = {
      Today: [moment().startOf('day'), moment().endOf('day')],
      Yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
      'Last 3 days': [moment().subtract(2, 'days').startOf('day'), moment().endOf('day')],
      'Last 7 days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
      // 'Last 14 days': [moment().subtract(13, 'days').startOf('day'), moment().endOf('day')],
      'Last 30 days': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
      // 'This month': [moment().startOf('month'), moment().endOf('month')],
      // 'Last month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      // 'Last 90 days': [moment().subtract(89, 'days').startOf('day'), moment().endOf('day')],
      'Last 6 months': [moment().subtract(6, 'month').startOf('day'), moment().endOf('day')],
      'All Time': [moment(session.getUser().createdAt).startOf('day'), moment().endOf('day')],
    };

    // Create DateRanges toDate(), .format() & Map
    this.rangesToDate = {};
    this.rangesFormat = {};
    this.rangesFormatMap = {};
    Object.entries(this.ranges).forEach(([label, [startDate, endDate]]) => {
      this.rangesToDate[label] = [startDate.toDate(), endDate.toDate()];
      const startDateFormat = startDate.format(FORMAT);
      const endDateFormat = endDate.format(FORMAT);
      this.rangesFormat[label] = [startDateFormat, endDateFormat];
      this.rangesFormatMap[`${startDateFormat}:${endDateFormat}`] = label;
    });
  }

  getDateRangesToDate(label) {
    return label ? this.rangesToDate[label] : this.rangesToDate;
  }

  getDateRangesFormated(label) {
    return label ? this.rangesFormat[label] : this.rangesFormat;
  }

  getLabelFromDateRangesFormated(range) {
    return range ? this.rangesFormatMap[`${range.startDate}:${range.endDate}`] : this.rangesFormatMap;
  }

  /**
   * Require { dateRange: {} }
   * @param {Object} payload
   */
  convertToLocalStorage(payload) {
    if (payload && payload.dateRange && typeof payload.dateRange === 'object') {
      const label = this.getLabelFromDateRangesFormated(payload.dateRange);
      // eslint-disable-next-line no-param-reassign
      if (label) payload.dateRange = label;
    }
    return payload;
  }

  /**
   * Require { dateRange: <{}|String> }
   * @param {Object|String} payload
   */
  convertFromLocalStorage(payload) {
    if (payload && payload.dateRange && typeof payload.dateRange === 'string') {
      const range = this.getDateRangesFormated(payload.dateRange);
      if (range) {
        const [startDate, endDate] = range;
        // eslint-disable-next-line no-param-reassign
        payload.dateRange = { startDate, endDate };
      } else {
        // eslint-disable-next-line no-param-reassign
        delete payload.dateRange;
      }
    }
    return payload;
  }
}

const getDateRangePresetsInstance = () => {
  if (!instance) instance = new DateRangePresets();
  return instance;
};

const getDateRangePresetsNewInstance = () => {
  instance = new DateRangePresets();
  return instance;
};

export { getDateRangePresetsInstance, getDateRangePresetsNewInstance };
