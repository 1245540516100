<template>
  <div class="lander-general-info">
    <!-- Preview -->
    <div class="lander-general-info__preview">
      <div @click.stop="handleAction('PREVIEW')" class="lander-general-info__preview__img" :style="{ background: `url('${lander.image}` }">
        <div class="lander-general-info__preview__gradient" />
        <lander-icon v-if="!lander.image" class="no-preview-icon" type="noPreview" />
        <div class="lander-general-info__preview__overlay-content">
          <lander-icon type="search" />
        </div>
      </div>
    </div>

    <!-- Lander Info -->
    <div class="lander-general-info__content" :class="{ 'not-editable': !editable }">
      <div class="lander-general-info__content__details">
        <div class="lander-general-info__content__details__name">
          <span @click="handleAction('NAME')" @dblclick.stop="editable ? handleAction('EDIT') : undefined">{{ lander.name }}</span>
          <a-tooltip v-if="editable" title="Rename" overlayClassName="ll-tooltip">
            <a-button @click.stop="handleAction('EDIT')" class="ll-btn ll-btn-icon ll-btn-edit"><a-icon
                type="edit" /></a-button>
          </a-tooltip>
        </div>
        <!-- <span v-if="isMasterLander" class="lander-general-info__content__details__master-badge">Main Page</span> -->
      </div>
      <div class="lander-general-info__content__specs" :class="{ 'not-published': !lander.publishedUrl }">
        <a-tooltip :title="lander.publishedUrl || undefined" overlayClassName="ll-tooltip">
          <a-button class="ll-btn ll-btn-text" @click.stop="openPublishedUrl">{{ publishedUrl }}</a-button>
        </a-tooltip>
        <a-tooltip v-if="editable && lander.publishedUrl" title="Edit" overlayClassName="ll-tooltip">
          <a-button @click.stop="handleAction('PUBLISH')" class="ll-btn ll-btn-icon ll-btn-edit"><a-icon type="edit" /></a-button>
        </a-tooltip>
      </div>
    </div>
    <span v-if="isMasterLander" class="lander-general-info__content__details__master-badge">Main Page</span>
  </div>
</template>

<script>
import { emits } from '@/mixins';
import { mapState } from 'pinia';
import { useWebsitesStore } from '@/stores/websites';
import component from '../mixins';

export default {
  name: 'LanderGeneralInfo',
  mixins: [component, emits],
  props: {
    editable: { type: Boolean, default: true },
    masterLanderId: { type: [Number, null], default: null },
  },
  computed: {
    isMasterLander() {
      return this.masterLanderId && this.masterLanderId === this.lander.id;
    },
    publishedUrl() {
      if (!this.lander.publishedUrl) return 'Not Published';
      if (this.lander.websiteId && this.website) return this.lander.publishedUrl.replace(this.website.publishedUrl, '') || '/';
      return this.lander.publishedUrl;
    },
    website() {
      if (this.lander.websiteId) {
        return this.websitesById[this.lander.websiteId];
      }
      return null;
    },
    ...mapState(useWebsitesStore, {
      websitesById: 'websitesById'
    })
  },
  methods: {
    openPublishedUrl() {
      if (this.publishedUrl !== 'Not Published') {
        window.open(`https://${this.lander.publishedUrl}`, '_blank');
      }
    },
    handleAction(action) {
      this.emitValue('action', { action, payload: this.lander });
    },
  },
};
</script>

<style lang="scss">
.lander-general-info {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;

  &__preview {
    width: 7.5rem;
    height: 5.2rem;
    min-height: 5.2rem;
    min-width: 7.5rem;

    &__img {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background-position: center top !important;
      background-size: cover !important;
      background-size: 100% !important;
      background-repeat: no-repeat !important;
      max-width: 100%;
      min-width: 100%;
      min-height: 100%;
      overflow: hidden;
      border-radius: .2rem;

      &:hover {
        .lander-general-info__preview__overlay-content {
          opacity: 1;
        }
      }
    }

    &__gradient {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(#8b9bf8, 0.3);
    }

    .no-preview-icon {
      z-index: 1;
      pointer-events: none;
    }

    &__overlay-content {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(#8b9bf8, 0.5);
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      z-index: 2;
      cursor: pointer;
      transition: .2s ease-in-out;

      .ll-icon {
        svg {
          height: 1.6rem;

          path {
            stroke: $color-white;
          }
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // row-gap: .2rem;
    max-width: 25vw;
    min-width: 20rem;

    &.not-editable {
      row-gap: .5rem;
    }

    &__details {
      display: flex;
      align-items: center;
      max-width: 100%;

      &__name {
        display: flex;
        align-items: center;
        line-height: 1.6rem;
        font-weight: 500;
        max-width: 100%;
        color: $color-black;

        span {
          display: inline-block;
          white-space: nowrap;
          max-width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          line-height: 1.2;
        }

        .ll-icon {
          svg {
            path {
              fill: $black-700 !important;
            }
          }
        }

        .ll-btn {
          height: 2.5rem !important;
          visibility: hidden;

          &:hover {
            background: none !important;

            svg {
              path {
                fill: $black-900 !important;
              }
            }
          }
        }

        &:hover {
          .ll-btn {
            visibility: visible;
          }
        }
      }

      &__master-badge {
        background: $blue-050;
        color: $black-700;
        display: inline-block;
        font-size: 1.1rem;
        margin-left: .5rem;
        padding: .3rem 1rem;
        font-weight: 600;
        white-space: nowrap;
        text-transform: uppercase;
      }
    }

    &__specs {
      display: flex;
      align-items: center;
      max-width: 100%;

      &.not-published {
        .ll-btn {
          color: $black-500 !important;
          pointer-events: none;
        }
      }

      .ll-btn-text {
        display: inline-flex;
        align-items: center;
        height: auto;
        line-height: 1.4rem;
        padding: 0 0 .3rem 0;
        font-weight: 400;
        color: #808080 !important;
        font-size: 1.4rem;
        border-bottom: .1rem dashed transparent;
        max-width: 100%;

        &:hover,
        &:focus,
        &:active {
          color: $black-600;
          border-color: $black-400;
        }

        span {
          max-width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          line-height: 1.6rem;
        }
      }

      .ll-btn-edit {
        height: 2rem !important;
        visibility: hidden;
        line-height: 1rem;
        transform: translateY(-.2rem);

        .anticon {
          color: $black-900;
        }

        &:hover {
          background: none !important;
        }
      }

      &:hover {
        .ll-btn-edit {
          visibility: visible;
        }
      }
    }
  }
}
</style>
