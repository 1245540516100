const grid = {
  // Form
  'cols-12': `
  <svg width="35" height="22" viewBox="0 0 35 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.75" y="0.75" width="33.5" height="20.5" rx="1.25" stroke="#32313E" stroke-width="1.5"/>
  </svg>
  `,
  'cols-6-6': `
  <svg width="35" height="22" viewBox="0 0 35 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.75" y="0.75" width="15.5" height="20.5" rx="1.25" stroke="#32313E" stroke-width="1.5"/>
    <rect x="18.75" y="0.75" width="15.5" height="20.5" rx="1.25" stroke="#32313E" stroke-width="1.5"/>
  </svg>
  `,
  'cols-4-4-4': `
  <svg width="35" height="22" viewBox="0 0 35 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="10.25" y="21.25" width="9.5" height="20.5" rx="1.25" transform="rotate(-180 10.25 21.25)" stroke="#32313E" stroke-width="1.5"/>
    <rect x="22.25" y="21.25" width="9.5" height="20.5" rx="1.25" transform="rotate(-180 22.25 21.25)" stroke="#32313E" stroke-width="1.5"/>
    <rect x="34.25" y="21.25" width="9.5" height="20.5" rx="1.25" transform="rotate(-180 34.25 21.25)" stroke="#32313E" stroke-width="1.5"/>
  </svg>
  `,
  'cols-3-3-3-3': `
    <svg width="35" height="22" viewBox="0 0 35 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="7.25" y="21.25" width="6.5" height="20.5" rx="1.25" transform="rotate(-180 7.25 21.25)" stroke="#32313E" stroke-width="1.5"/>
      <rect x="16.25" y="21.25" width="6.5" height="20.5" rx="1.25" transform="rotate(-180 16.25 21.25)" stroke="#32313E" stroke-width="1.5"/>
      <rect x="25.25" y="21.25" width="6.5" height="20.5" rx="1.25" transform="rotate(-180 25.25 21.25)" stroke="#32313E" stroke-width="1.5"/>
      <rect x="34.25" y="21.25" width="6.5" height="20.5" rx="1.25" transform="rotate(-180 34.25 21.25)" stroke="#32313E" stroke-width="1.5"/>
    </svg>
  `,
  'cols-2-2-2-2-2-2': `
    <svg width="35" height="22" viewBox="0 0 35 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4.25" y="21.25" width="3.5" height="20.5" rx="1.25" transform="rotate(-180 4.25 21.25)" stroke="#32313E" stroke-width="1.5"/>
      <rect x="10.25" y="21.25" width="3.5" height="20.5" rx="1.25" transform="rotate(-180 10.25 21.25)" stroke="#32313E" stroke-width="1.5"/>
      <rect x="16.25" y="21.25" width="3.5" height="20.5" rx="1.25" transform="rotate(-180 16.25 21.25)" stroke="#32313E" stroke-width="1.5"/>
      <rect x="22.25" y="21.25" width="3.5" height="20.5" rx="1.25" transform="rotate(-180 22.25 21.25)" stroke="#32313E" stroke-width="1.5"/>
      <rect x="28.25" y="21.25" width="3.5" height="20.5" rx="1.25" transform="rotate(-180 28.25 21.25)" stroke="#32313E" stroke-width="1.5"/>
      <rect x="34.25" y="21.25" width="3.5" height="20.5" rx="1.25" transform="rotate(-180 34.25 21.25)" stroke="#32313E" stroke-width="1.5"/>
    </svg>  
  `,
  'cols-9-3': `
  <svg width="34" height="22" viewBox="0 0 34 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.75" y="0.75" width="22.5" height="20.5" rx="1.25" stroke="#32313E" stroke-width="1.5"/>
    <rect x="25.75" y="0.75" width="7.5" height="20.5" rx="1.25" stroke="#32313E" stroke-width="1.5"/>
  </svg>
  `,
  'cols-3-9': `
  <svg width="34" height="22" viewBox="0 0 34 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="33.25" y="21.25" width="22.5" height="20.5" rx="1.25" transform="rotate(-180 33.25 21.25)" stroke="#32313E" stroke-width="1.5"/>
    <rect x="8.25" y="21.25" width="7.5" height="20.5" rx="1.25" transform="rotate(-180 8.25 21.25)" stroke="#32313E" stroke-width="1.5"/>
  </svg>
  `,
  'rows-12': `
  <svg width="35" height="22" viewBox="0 0 35 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.75" y="0.75" width="33.5" height="9.5" rx="1.25" stroke="#32313E" stroke-width="1.5"/>
    <rect x="0.75" y="12.75" width="33.5" height="8.5" rx="1.25" stroke="#32313E" stroke-width="1.5"/>
  </svg>
  `,
  'rows-6-6': `
  <svg width="35" height="22" viewBox="0 0 35 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.75" y="0.75" width="15.5" height="9.5" rx="1.25" stroke="#32313E" stroke-width="1.5"/>
    <rect x="0.75" y="12.75" width="15.5" height="8.5" rx="1.25" stroke="#32313E" stroke-width="1.5"/>
    <rect x="18.75" y="0.75" width="15.5" height="9.5" rx="1.25" stroke="#32313E" stroke-width="1.5"/>
    <rect x="18.75" y="12.75" width="15.5" height="8.5" rx="1.25" stroke="#32313E" stroke-width="1.5"/>
  </svg>
  `,
  'rows-4-4-4': `
    <svg width="35" height="22" viewBox="0 0 35 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="10.25" y="10.25" width="9.5" height="9.5" rx="1.25" transform="rotate(-180 10.25 10.25)" stroke="#32313E" stroke-width="1.5"/>
      <rect x="10.25" y="21.25" width="9.5" height="8.5" rx="1.25" transform="rotate(-180 10.25 21.25)" stroke="#32313E" stroke-width="1.5"/>
      <rect x="22.25" y="10.25" width="9.5" height="9.5" rx="1.25" transform="rotate(-180 22.25 10.25)" stroke="#32313E" stroke-width="1.5"/>
      <rect x="22.25" y="21.25" width="9.5" height="8.5" rx="1.25" transform="rotate(-180 22.25 21.25)" stroke="#32313E" stroke-width="1.5"/>
      <rect x="34.25" y="10.25" width="9.5" height="9.5" rx="1.25" transform="rotate(-180 34.25 10.25)" stroke="#32313E" stroke-width="1.5"/>
      <rect x="34.25" y="21.25" width="9.5" height="8.5" rx="1.25" transform="rotate(-180 34.25 21.25)" stroke="#32313E" stroke-width="1.5"/>
    </svg>
  `,
};

export default grid;
