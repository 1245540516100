import axios from 'axios';
import router from '@/router';
import session from './session';
// import mp from './mixpanel';

// App Url
const apiUrl = process.env.VUE_APP_API_URL;

// Create axios request instance
const request = axios.create({ baseURL: apiUrl, withCredentials: true });
request.defaults.timeout = 5 * 60 * 1000;

function registerResponseInterception(req) {
  req.interceptors.response.use((response) => response, (error) => {
    if (router.currentRoute.name !== 'Login' && error.response && (error.response.status === 401 /* || error.response.status === 403 */)) {
      session.removeToken();
      router.push({ name: 'Login', query: router.history.current.query });
      // store.dispatch('signOut');

      // vm.$notification.error({
      //   message: 'Access Expired.',
      //   description: 'Please login again to use application.',
      // });
      return Promise.reject(new Error('Access Expired.'));
    }
    if (error.response.status !== 401) {
      // mp.track('Error', { status: error.response.status, data: JSON.stringify(error.response.data) });
    }
    return Promise.reject(error);
  });
}

// Check user for authorization
registerResponseInterception(request);

request.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${session.getToken()}`;
  return config;
}, (err) => Promise.reject(err));

export default request;
