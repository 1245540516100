<template>
  <date-range-picker
    ref="dateRange"
    class="ll-date-range"
    :opens="opens"
    :locale-data="{ firstDay: 1, format: 'mmm d, yyyy', daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'] }"
    :min-date="minDate"
    :max-date="maxDate"
    :single-date-picker="singleDatePicker"
    :time-picker="timePicker"
    :time-picker24hour="true"
    :show-week-numbers="false"
    :show-dropdowns="false"
    :auto-apply="singleDatePicker || autoApply"
    :ranges="singleDatePicker ? {} : ranges"
    date-util="moment"
    v-model="dateRange"
    @update="updateValues"
    :linked-calendars="true"
  >
    <template v-slot:input>
      <a-icon type="calendar" /> <span v-html="inputValue" />
    </template>
    <template #ranges="{ ranges: presets }">
      <div class="ranges">
        <ul>
          <li v-for="(range, label) in presets" :key="label" @click="handleRangeSelect(range)">{{ label }}</li>
        </ul>
      </div>
    </template>
  </date-range-picker>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import { getDateRangePresetsInstance } from './DateRangePresets';

/* eslint-disable */
import moment from 'moment-timezone';

const FORMAT = 'YYYY-MM-DD';

const defaultRange = {
  startDate: moment(new Date()).format(FORMAT),
  endDate: moment(new Date()).endOf('day').format(FORMAT),
};

export default {
  name: 'DateRange',
  components: { DateRangePicker },
  props: {
    query: { type: Boolean, default: true },
    opens: { type: String, default: 'left' },
    minDate: { type: [String, Date, null], default: null },
    maxDate: { type: [String, Date, null], default: null },
    autoApply: { type: Boolean, default: false },
    singleDatePicker: { type: Boolean, default: false },
    timePicker: { type: Boolean, default: false },
    autoEmit: { type: Boolean, default: false },
    value: { type: [Object, String, null], default: null },
    disableDates: { type: Function, default: () => ({}) }
  },
  data() {
    return {
      dateRange: { startDate: '', endDate: '' },
      ranges: {},
    };
  },
  computed: {
    inputValue() {
      if (this.singleDatePicker) {
        return `${moment(this.dateRange.startDate).format('MMM, D YYYY')}`
      }
      if (this.dateRange.startDate && this.dateRange.endDate) {
        let dateText = '';
        for (let key in this.ranges) {
          if (moment(this.ranges[key][0]).format(FORMAT) === this.dateRange.startDate && moment(this.ranges[key][1]).format(FORMAT) === this.dateRange.endDate) {
            if (key === 'Today') {
              dateText = `Today <small>( ${moment().startOf('day').format('MMM, D YYYY')} )</small>`
            } else if (key === 'Yesterday') {
              dateText = `Yesterday <small>( ${moment().subtract(1, 'days').startOf('day').format('MMM, D YYYY')} )</small>`
            } else {
              dateText = key;
            }
            break;
          }
        }
        return dateText.length > 0 ? dateText : `${moment(this.dateRange.startDate).format('MMM, D YYYY')} - ${moment(this.dateRange.endDate).format('MMM, D YYYY')}`;
      }
      return '';
    }
  },
  created() {
    const dateRangePresets = getDateRangePresetsInstance();
    this.ranges = dateRangePresets.getDateRangesToDate();

    /* ************* Set DateRange ************** */
    let dateRange = null;
    try {
      // Set DateRange from v-model
      dateRange = { startDate: this.value.startDate, endDate: this.value.endDate };
    } catch (err) {
      // Set DateRange from $route URI
      if (this.query) {
        const { startDate, endDate } = this.$route.query;
        if (startDate && endDate) dateRange = { startDate, endDate };
      }
    }
    // If neither set Default DateRange
    if (!dateRange) dateRange = { ...defaultRange };
    this.dateRange = { ...dateRange };
    /* ************* ************* ************** */

    // Sync $route URI
    this.updateQuery(this.dateRange)

    // Auto emit
    if (this.autoEmit) this.emit()
  },
  methods: {
    updateValues({ startDate, endDate }) {
      this.dateRange = { startDate: moment(startDate).format(FORMAT), endDate: moment(endDate).format(FORMAT) };
      this.updateQuery(this.dateRange)
      this.emit();
      this.$emit('change', this.dateRange);
    },
    emit() {
      this.$emit('input', this.dateRange);
      this.$emit('update:label', this.inputValue);
    },
    updateQuery({ startDate, endDate }) {
      if (this.query) {
        const { startDate: qStartDate, endDate: qEndDate } = this.$route.query;
        const { params } = this.$route;
        if (qStartDate !== startDate || qEndDate !== endDate) {
          this.$router.replace({ params, query: { ...this.$route.query, startDate, endDate } });
        }
      }
    },
    handleRangeSelect([startDate, endDate]) {
      this.$refs.dateRange.togglePicker();
      this.updateValues({ startDate, endDate });
    },
  },
};
</script>

<style lang="scss">

</style>
