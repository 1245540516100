/* eslint-disable prefer-regex-literals */

export default {
  // Validate Outgoing Link
  validateOutgoingLink(url) {
    return !!new RegExp('^(https?:\\/\\/)'
        + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
        + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
        + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
        + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
        + '(\\#[-a-z\\d_]*)?$', 'i').test(url); // fragment locator
  },

  // Validate Subdomain Name
  validateSubdomainName(text) {
    return !!new RegExp('^[a-zA-Z0-9\\-"]*$').test(text);
  },

  // Validate Domain doesn't include http or https
  validateDomain(url) {
    return !!new RegExp('^(https?:\\/\\/)?'
        + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
        + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
        + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
        + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
        + '(\\#[-a-z\\d_]*)?$', 'i').test(url); // fragment locator
  },

  // Validate url shouldn't contain protocol
  validateUrlWithoutProtocol(url) {
    return !new RegExp('^(?:[a-z]+:)?//', 'i').test(url);
  },

  // Validate Ip should be valid Ip Address
  validateIpAddress(ip) {
    return !!(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ip));
  },
};
