import Vue from 'vue';
import VueRouter from 'vue-router';
import {
  isLoggedIn, notLoggedIn, freeTrialAcquired, onboardingCompleted,
} from './guards';

/* Containers */
const DashboardContainer = () => import('@/containers/Dashboard.vue');
const AuthContainer = () => import('@/containers/Auth.vue');
const OnBoardingContainer = () => import('@/containers/OnBoarding.vue');

/* OnBoarding */
const Welcome = () => import('@/views/OnBoarding/Welcome.vue');
const ChoosePlan = () => import('@/views/OnBoarding/ChoosePlan.vue');

/* Authentication */
const Login = () => import('@/views/Authentication/Login.vue');
const Register = () => import('@/views/Authentication/Register.vue');
const ResetPassword = () => import('@/views/Authentication/ResetPassword.vue');

/* Dashboard */
const Dashboard = () => import('@/views/Dashboard/Dashboard/Dashboard.vue');
const LandingPages = () => import('@/views/Dashboard/LandingPages/LandingPages.vue');

const WizardType = () => import('@/views/Dashboard/Wizard/Type/Type.vue');
const WizardTemplate = () => import('@/views/Dashboard/Wizard/Template/Template.vue');
const WizardZip = () => import('@/views/Dashboard/Wizard/Zip/Zip.vue');
const WizardUrl = () => import('@/views/Dashboard/Wizard/Url/Url.vue');
const WizardImport = () => import('@/views/Dashboard/Wizard/Import/Import.vue');

const Websites = () => import('@/views/Dashboard/Websites/Websites.vue');
const Website = () => import('@/views/Dashboard/Website/Website.vue');

const Folder = () => import('@/views/Dashboard/Folder/Folder.vue');
const Lander = () => import('@/views/Dashboard/Lander/Lander.vue');
const Templates = () => import('@/views/Dashboard/Templates/Templates.vue');
const Domains = () => import('@/views/Dashboard/Domains/Domains.vue');
const Leads = () => import('@/views/Dashboard/Leads/Leads.vue');
const Reporting = () => import('@/views/Dashboard/Reporting/Reporting.vue');

const Settings = () => import('@/views/Dashboard/Settings/Settings.vue');

const Account = () => import('@/views/Dashboard/Account/Account.vue');

const Editor = () => import('@/views/Dashboard/Editor/Editor.vue');

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'DashboardContainer',
    redirect: { name: 'Dashboard' },
    component: DashboardContainer,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
        beforeEnter: notLoggedIn,
      },
      {
        path: 'landing-pages',
        name: 'LandingPages',
        component: LandingPages,
        beforeEnter: notLoggedIn,
      },
      {
        path: 'landing-pages/create',
        name: 'WizardType',
        component: WizardType,
        beforeEnter: notLoggedIn,
      },
      {
        path: 'landing-pages/create/template',
        name: 'WizardTemplate',
        component: WizardTemplate,
        beforeEnter: notLoggedIn,
      },
      {
        path: 'landing-pages/create/zip',
        name: 'WizardZip',
        component: WizardZip,
        beforeEnter: notLoggedIn,
      },
      {
        path: 'landing-pages/create/url',
        name: 'WizardUrl',
        component: WizardUrl,
        beforeEnter: notLoggedIn,
      },
      {
        path: 'landing-pages/create/adplexity',
        name: 'WizardAdplexity',
        redirect: { name: 'WizardImport' },
        beforeEnter: notLoggedIn,
      },
      {
        path: 'landing-pages/create/import',
        name: 'WizardImport',
        component: WizardImport,
        beforeEnter: notLoggedIn,
      },
      {
        path: 'wizard',
        name: 'Wizard',
        redirect: { name: 'WizardImport' },
        beforeEnter: notLoggedIn,
      },
      {
        path: 'landing-pages/folder/:id',
        name: 'Folder',
        component: Folder,
        beforeEnter: notLoggedIn,
      },
      {
        path: 'landing-pages/:id/:tab?',
        name: 'Lander',
        component: Lander,
        beforeEnter: notLoggedIn,
      },
      {
        path: 'websites',
        name: 'Websites',
        component: Websites,
        beforeEnter: notLoggedIn,
      },
      {
        path: 'websites/create/template',
        name: 'WebsitesWizardTemplate',
        component: WizardTemplate,
      },
      {
        path: 'websites/:id/:tab?',
        name: 'Website',
        component: Website,
        beforeEnter: notLoggedIn,
      },
      {
        path: 'templates',
        name: 'Templates',
        component: Templates,
        beforeEnter: notLoggedIn,
      },
      {
        path: 'domains',
        name: 'Domains',
        component: Domains,
        beforeEnter: notLoggedIn,
      },
      {
        path: 'leads',
        name: 'Leads',
        component: Leads,
        beforeEnter: notLoggedIn,
      },
      {
        path: 'reporting',
        name: 'Reporting',
        component: Reporting,
        beforeEnter: notLoggedIn,
      },
      {
        path: 'settings/:tab?',
        name: 'Settings',
        component: Settings,
        beforeEnter: notLoggedIn,
      },
      {
        path: '/account/:tab?',
        name: 'Account',
        component: Account,
      },
    ],
  },
  {
    path: '/editor/:type?/:id',
    name: 'Editor',
    component: Editor,
    beforeEnter: notLoggedIn,
  },
  {
    path: '/auth',
    component: AuthContainer,
    redirect: { name: 'Login' },
    beforeEnter: isLoggedIn,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: Login,
      },
      {
        path: 'register',
        name: 'Register',
        component: Register,
      },
      {
        path: 'reset-password',
        name: 'ResetPassword',
        component: ResetPassword,
      },
    ],
  },
  {
    path: '/onboarding',
    component: OnBoardingContainer,
    name: 'OnBoardingContainer',
    redirect: { name: 'Welcome' },
    children: [
      {
        path: 'welcome',
        name: 'Welcome',
        component: Welcome,
        beforeEnter: onboardingCompleted,
      },
      {
        path: 'plan',
        name: 'ChoosePlan',
        component: ChoosePlan,
        beforeEnter: freeTrialAcquired,
      },
    ],
  },
  {
    path: '*',
    name: 'Page404',
    redirect: { name: 'Dashboard' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

// router.beforeEach((to, from, next) => {
//   if ((from && to && from.name !== to.name) || !from) {
//     mp.track(mpConfig.PAGE_VIEW, { page: camelCaseToSpaces(to.name).trim(), ...to.query });
//   }
//   next();
// });

export default router;
