import Vue from 'vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import DateRange from '@/components/Utils/DateRange/DateRange.vue';
import LanderIcon from '@/components/Utils/Icon/LanderIcon.vue';
import LanderCard from '@/components/Utils/Card/LanderCard.vue';
import PageHead from '@/components/Pages/PageHead.vue';
import LanderGeneralInfo from '@/components/Lander/components/LanderGeneralInfo.vue';
import LanderSearch from '@/components/Utils/Search/LanderSearch.vue';
import LanderEmpty from '@/components/Utils/Empty/LanderEmpty.vue';
import LanderSkeleton from '@/components/Utils/Skeleton/LanderSkeleton.vue';
import ConfirmModal from '@/components/Modals/Utils/Confirm/ConfirmModal.vue';

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('DateRange', DateRange);
Vue.component('LanderIcon', LanderIcon);
Vue.component('PageHead', PageHead);
Vue.component('LanderGeneralInfo', LanderGeneralInfo);
Vue.component('LanderSearch', LanderSearch);
Vue.component('LanderCard', LanderCard);
Vue.component('LanderEmpty', LanderEmpty);
Vue.component('LanderSkeleton', LanderSkeleton);
Vue.component('ConfirmModal', ConfirmModal);
