import Vue from 'vue';
import axios from '@/services/axios';
import api from '@/services/api';
import session from '@/services/session';
import userLocalStorage from '@/services/userLocalStorage';

Vue.prototype.$session = session;
Vue.prototype.$axios = axios;
Vue.prototype.$api = api;
Vue.prototype.$userLocalStorage = userLocalStorage;

Vue.prototype.$plans = {
  LEGACY: 0, STARTER: 1, PRO: 2, MASTER: 3,
};
