<template>
  <div class="ll-empty">
    <div class="ll-empty__icon">
      <lander-icon :type="icon" />
    </div>
    <p class="ll-empty__description">
      <template v-if="$slots['description']"><slot name="description" /></template>
      <template v-else>{{ description }}</template>
    </p>
    <a-button v-if="actions" class="ll-btn ll-btn--large ll-btn-primary" @click="emitEvent('add')"><a-icon type="plus" />{{ buttonText }}</a-button>
  </div>
</template>

<script>
import { emits } from '@/mixins';

export default {
  name: 'LanderEmpty',
  mixins: [emits],
  props: {
    icon: { type: String, default: 'landingPagesIllustration' },
    description: { type: String, default: 'Build your audiences by easily installing any tracking pixel for popular platforms like Facebook, Google etc.' },
    actions: { type: Boolean, default: true },
    buttonText: { type: String, default: 'Add Item' },
  },
};
</script>

<style lang="scss">
  .ll-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    text-align: center;

    &__icon {
      .ll-icon {
        width: 28rem;

        svg {
          height: 100%;
          width: 100%;
        }
      }
    }

    &__description {
      max-width: 40rem;
      color: $black-500;
      font-size: 1.6rem;
    }

    .ll-btn {
      i {
        display: flex;
        align-items: center;
      }
    }
  }
</style>
