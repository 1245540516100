const toolbar = {
  toolbarMove: `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.8726 20.6899L13 23.5625L10.1274 20.6899" stroke="#32313E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13 16.25V23.5625" stroke="#32313E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.1274 5.31009L13 2.4375L15.8726 5.31009" stroke="#32313E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13 9.75V2.4375" stroke="#32313E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5.31009 15.8726L2.4375 13L5.31009 10.1274" stroke="#32313E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9.75 13H2.4375" stroke="#32313E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M20.6899 10.1274L23.5625 13L20.6899 15.8726" stroke="#32313E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.25 13H23.5625" stroke="#32313E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  `,
  toolbarPlus: `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 8.6V17.4M8.6 13H17.4M24 13C24 19.0751 19.0751 24 13 24C6.92487 24 2 19.0751 2 13C2 6.92487 6.92487 2 13 2C19.0751 2 24 6.92487 24 13Z" stroke="#32313E" stroke-width="1.55" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  `,
  toolbarDuplicate: `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.9367 18.687V4.06189H7.31104" stroke="#32313E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.6866 7.31201H4.06104V21.937H18.6866V7.31201Z" stroke="#32313E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  `,
  toolbarSelectParent: `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.375 20.7188L11.3742 14.6258L5.28125 14.625" stroke="#32313E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4.05957 21.9404L11.3721 14.6279" stroke="#32313E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.25 19.5H21.125C21.3405 19.5 21.5472 19.4144 21.6995 19.262C21.8519 19.1097 21.9375 18.903 21.9375 18.6875V4.875C21.9375 4.65951 21.8519 4.45285 21.6995 4.30048C21.5472 4.1481 21.3405 4.0625 21.125 4.0625H7.3125C7.09701 4.0625 6.89035 4.1481 6.73798 4.30048C6.5856 4.45285 6.5 4.65951 6.5 4.875V9.75" stroke="#32313E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  `,
  toolbarDelete: ` 
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.9371 5.6875L4.06213 5.68751" stroke="#32313E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.5625 10.5625V17.0625" stroke="#32313E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15.4375 10.5625V17.0625" stroke="#32313E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M20.3125 5.6875V21.125C20.3125 21.3405 20.2269 21.5472 20.0745 21.6995C19.9222 21.8519 19.7155 21.9375 19.5 21.9375H6.5C6.28451 21.9375 6.07785 21.8519 5.92548 21.6995C5.7731 21.5472 5.6875 21.3405 5.6875 21.125V5.6875" stroke="#32313E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17.0625 5.6875V4.0625C17.0625 3.63152 16.8913 3.2182 16.5865 2.91345C16.2818 2.6087 15.8685 2.4375 15.4375 2.4375H10.5625C10.1315 2.4375 9.7182 2.6087 9.41345 2.91345C9.10871 3.2182 8.9375 3.63152 8.9375 4.0625V5.6875" stroke="#32313E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  `,
  toolbarGroup: `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.9375 9.75C21.9375 9.30127 21.5737 8.9375 21.125 8.9375H13H4.875C4.42627 8.9375 4.0625 9.30127 4.0625 9.75V21.125C4.0625 21.5737 4.42627 21.9375 4.875 21.9375H21.125C21.5737 21.9375 21.9375 21.5737 21.9375 21.125V9.75Z" stroke="#32313E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9.34375 8.9375V5.28125C9.34375 4.31155 9.72896 3.38157 10.4146 2.69589C11.1003 2.01021 12.0303 1.625 13 1.625C13.9697 1.625 14.8997 2.01021 15.5854 2.69589C16.271 3.38157 16.6562 4.31155 16.6562 5.28125V8.9375" stroke="#32313E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13 16.6562C13.6731 16.6562 14.2188 16.1106 14.2188 15.4375C14.2188 14.7644 13.6731 14.2188 13 14.2188C12.3269 14.2188 11.7812 14.7644 11.7812 15.4375C11.7812 16.1106 12.3269 16.6562 13 16.6562Z" fill="#32313E"/>
    </svg>
  `,
  toolbarUp: `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.3329 22.1111C16.3329 22.4223 16.3329 22.5778 16.2723 22.6967C16.2191 22.8012 16.1341 22.8862 16.0296 22.9394C15.9107 23 15.7552 23 15.444 23L10.5551 23C10.244 23 10.0884 23 9.96957 22.9394C9.86504 22.8862 9.78005 22.8012 9.72678 22.6967C9.66623 22.5778 9.66623 22.4223 9.66623 22.1111L9.66623 10.7778L5.22179 10.7778L12.9996 3L20.7773 10.7778L16.3329 10.7778L16.3329 22.1111Z" stroke="#32313E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  `,
  toolbarDown: `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.6671 3.88889C9.6671 3.57775 9.6671 3.42218 9.72765 3.30334C9.78092 3.1988 9.8659 3.11381 9.97044 3.06055C10.0893 3 10.2448 3 10.556 3H15.4449C15.756 3 15.9116 3 16.0304 3.06055C16.135 3.11381 16.22 3.1988 16.2732 3.30334C16.3338 3.42218 16.3338 3.57775 16.3338 3.88889V15.2222H20.7782L13.0004 23L5.22266 15.2222H9.6671V3.88889Z" stroke="#32313E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  `,
  toolbarRight: `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.88889 16.3334C3.57775 16.3334 3.42218 16.3334 3.30334 16.2728C3.1988 16.2196 3.11382 16.1346 3.06055 16.03C3 15.9112 3 15.7556 3 15.4445L3 10.5556C3 10.2445 3 10.0889 3.06055 9.97006C3.11382 9.86552 3.19881 9.78054 3.30334 9.72727C3.42218 9.66672 3.57775 9.66672 3.88889 9.66672L15.2222 9.66672L15.2222 5.22228L23 13.0001L15.2222 20.7778L15.2222 16.3334L3.88889 16.3334Z" stroke="#32313E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  `,
  toolbarLeft: `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.1111 9.66661C22.4223 9.66661 22.5778 9.66661 22.6967 9.72716C22.8012 9.78043 22.8862 9.86542 22.9394 9.96995C23 10.0888 23 10.2444 23 10.5555L23 15.4444C23 15.7555 23 15.9111 22.9394 16.0299C22.8862 16.1345 22.8012 16.2195 22.6967 16.2727C22.5778 16.3333 22.4223 16.3333 22.1111 16.3333L10.7778 16.3333L10.7778 20.7777L3 12.9999L10.7778 5.22217L10.7778 9.66661L22.1111 9.66661Z" stroke="#32313E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  `,
};

export default toolbar;
