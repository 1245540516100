<template>
  <div id="app">
    <div v-if="loading" class="ll-load">
      <img alt="" src="./loading.gif" />
    </div>
    <router-view v-else />
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { useUserStore } from './stores/user';
import 'ant-design-vue/dist/antd.css';
import '@/styles/main.scss';

export default {
  name: 'App',
  computed: {
    ...mapState(useUserStore, {
      loading: 'loading'
    })
  }
};
</script>

<style lang="scss">
.ll-load {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba($color-white, .25);
  z-index: 10;

  img {
    height: 4rem;
  }
}
</style>
