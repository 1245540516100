const pushToDataLayer = ({ event, user = {}, signupType }) => {
  window.dataLayer = window.dataLayer || [];

  const payload = { event };

  if (Object.keys(user).length) {
    payload.userID = user.id;
    payload.firstName = user.firstName;
    payload.lastName = user.lastName;
    payload.email = user.email;
    payload.subscriptionPlan = 'Open Beta';
  }

  if (signupType) payload.signupType = signupType;

  window.dataLayer.push(payload);
};

export {
  pushToDataLayer
};
