import moment from 'moment';
import { defineStore } from 'pinia';
import api from '@/services/api';

export const useUserStore = defineStore('user', {
  state: () => ({
    loading: false,
    user: {},
    limits: {},
    loaded: false,
  }),
  actions: {
    async getUser(force) {
      if (!this.loaded || force) {
        try {
          const { user, limits } = await api.user.me();
          this.user = user;
          this.limits = limits;
          this.user.timezone = user.timezone || moment.tz.guess();
          this.loaded = true;
        } catch (err) {
          console.log(err);
        }
      }
    },
    updateUser({ user, limits }) {
      this.user = user;
      this.limits = limits;
    },
    clearStore() {
      this.$reset();
    },
  },
});
