/* eslint-disable */
import merge from 'lodash.merge';
import session from './session';

const getNestedValue = (key, obj) => {
  try {
    const keys = key.split('.');
    let pointerValue = obj;
    keys.forEach((k) => { pointerValue = pointerValue[k]; });
    return pointerValue;
  } catch (error) {
    return undefined;
  }
};

const setNestedValue = (key, obj, newObj, shouldMerge = false) => {
  try {
    const keys = key.split('.');
    const lastKey = keys.pop();
    let pointer = obj;
    keys.forEach((k) => { pointer = pointer[k]; });
    if (shouldMerge && typeof pointer[lastKey] === 'object' && typeof newObj === 'object') {
      merge(pointer[lastKey], newObj);
    } else {
      pointer[lastKey] = newObj;
    }
  } catch (error) {
    // Do nothing
  }
};

class UserLocalStorage {
  constructor() {
    this.localStorageKey = null;

    /* ************ Concstructor ************* */
    session.onAuthentication(() => {
      this._setLocalStorageKey(session.getUser().id);
    });

    session.onLogout(() => {
      this._setLocalStorageKey(null);
    });

    if (session.isAuthenticated()) {
      this._setLocalStorageKey(session.getUser().id);
    } else {
      this._setLocalStorageKey(null);
    }
    /* ************************************** */
  }

  /**
   * Replace settings
   * @param String key, (support nested)
   * @param Any value
   */
  setSettings(key, value) {
    if (key) {
      const allSettings = this._getSettingsFromLocalStorage() || {};
      setNestedValue(key, allSettings, value);
      this._setSettingsToLocalStorage(allSettings);
    }
  }

  /**
   * Merge settings
   * @param String key, (support nested)
   * @param Any value
   */
  updateSettings(key, value) {
    if (key) {
      const allSettings = this._getSettingsFromLocalStorage() || {};
      setNestedValue(key, allSettings, value, true);
      this._setSettingsToLocalStorage(allSettings);
    }
  }

  /**
   * @param String key, (support nested)
   * @param Any value
   */
  getSettings(key) {
    const allSettings = this._getSettingsFromLocalStorage();
    return key ? getNestedValue(key, allSettings) : allSettings;
  }

  /* Get User Settings from Local Storage */
  _getSettingsFromLocalStorage() {
    if (!this.localStorageKey) return;

    try {
      return JSON.parse(localStorage.getItem(this.localStorageKey));
    } catch (err) {
      console.log(err);
      // Delete if error
      localStorage.removeItem(this.localStorageKey);
      return undefined;
    }
  }

  /* Set User Settings to Local Storage */
  _setSettingsToLocalStorage(payload) {
    if (!this.localStorageKey) return;

    try {
      localStorage.setItem(this.localStorageKey, JSON.stringify(payload));
    } catch (err) {
      console.log(err);
    }
  }

  /* Set Local Storage Key */
  _setLocalStorageKey(userId) {
    this.localStorageKey = userId ? `ll-user-${userId}-settings` : null;
  }
}

const userLocalStorage = new UserLocalStorage();

export default userLocalStorage;