// Website Published URL
const websiteUrl = ({ domain, path }) => {
  domain = domain.replace(/^https?:\/\//, '');
  path = path === '/' ? '' : path;

  return `${domain}${path}`;
};

// Map Website
const mapWebsite = ((website) => {
  const { masterLander, domain, path } = website;

  const previewURL = masterLander?.masterVariant?.previewURL;
  const image = masterLander?.masterVariant?.image;

  return {
    ...website,
    previewURL,
    image,
    publishedUrl: website.published ? websiteUrl({ domain: domain.name, path }) : '',
  };
});

export {
  websiteUrl,
  mapWebsite
};
