<template>
  <a-modal
    :visible="modal"
    class="ll-modal"
    wrapClassName="ll-modal--confirm"
    centered
    :width="500"
    :zIndex="1100"
    :maskClosable="!loading"
    :closable="false"
    :afterClose="handleModalHide"
    @cancel="hideModal"
  >
    <div class="confirm-modal-container">
      <h4 class="confirm-modal-title">{{ config.title }}</h4>
      <p v-if="config.description" v-html="config.description" class="confirm-modal-description" />
    </div>

    <template #footer>
      <div class="modal-footer-actions">
        <a-button @click="emitValue('confirm', { action, payload: item })" :class="`ll-btn ll-btn-primary ${buttonClass[type]}`" :loading="loading" :disabled="loading">{{ config.confirmButtonText }}</a-button>
        <a-button @click="handleCancel" class="ll-btn ll-btn-primary ll-btn-primary--outline" :disabled="loading">{{ config.cancelButtonText }}</a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { modal, emits } from '@/mixins';

export default {
  name: 'ConfirmModal',
  mixins: [modal, emits],
  data() {
    return {
      loading: false,
      item: null,
      type: 'DELETE', // DELETE | WARNING | INFO,
      action: 'DELETE',
      config: {
        title: 'Are you sure?',
        description: '',
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
      },
      buttonClass: {
        DELETE: 'll-btn-primary--danger',
        WARNING: 'll-btn-primary--warning',
        INFO: 'll-btn-primary--info',
      },
    };
  },
  methods: {
    showModal(data = null) {
      this.setModal(data);
      this.modal = true;
    },
    setModal({
      type = 'DELETE', action = 'DELETE', payload = null, config = {}
    }) {
      this.type = type;
      this.action = action;
      this.item = payload;

      if (config.title) this.config.title = config.title;
      if (config.description) this.config.description = config.description;
      if (config.confirmButtonText) this.config.confirmButtonText = config.confirmButtonText;
      if (config.cancelButtonText) this.config.cancelButtonText = config.cancelButtonText;
    },
    setLoading(value) {
      this.loading = value;
    },
    handleCancel() {
      this.emitEvent('cancel');
      this.hideModal();
    },
    resetState() {
      this.item = null;
      this.type = 'DELETE';
      this.action = 'DELETE';
      this.config.title = 'Are you sure?';
      this.config.description = '';
      this.config.confirmButtonText = 'Delete';
      this.config.cancelButtonText = 'Cancel';
    },
    handleModalHide() {
      this.resetState();
      this.emitEvent('after-close');
    },
  },
};
</script>

<style lang="scss">
  .ll-modal--confirm {
    .confirm-modal-container {
      padding: 1rem 2rem;
      text-align: center;

      .confirm-modal-title {
        font-size: 2.2rem;
        font-weight: 700;
        text-align: center;
        color: $black-900;
      }

      .confirm-modal-description {
        font-size: 1.5rem;
        color: $black-500;
        text-align: center;
        padding: 1rem 1.5rem 0rem 1.5rem;
        // font-style: italic;

        b {
          color: $black-700;
          padding-bottom: .2rem;
          border-bottom: .1rem dashed $black-300;
        }
      }
    }

    .ant-modal-footer {
      // border: none;

      .modal-footer-actions {
        display: flex;
        justify-content: center;
        gap: .5rem;
        width: 100%;

        .ll-btn {
          min-width: 14rem;
        }
      }
    }
  }
</style>
