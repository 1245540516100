import {
  required, email, numeric, confirmed, min, max
} from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';

// Custom Validation Functions
import validationFunctions from './functions';

extend('required', {
  ...required,
  message: 'The {_field_} field is required',
});

extend('email', {
  ...email,
  message: 'The {_field_} field must be a valid email',
});

extend('numeric', {
  ...numeric,
  message: 'The {_field_} field should contain only numbers',
});

extend('confirmed', {
  ...confirmed,
  message: 'The {_field_} field does not match',
});

extend('min', {
  ...min,
  params: ['min'],
  validate(value, { min }) {
    return value.length >= min;
  },
  message: 'The {_field_} field must be at least {min} characters',
});

extend('max', {
  ...max,
  params: ['max'],
  validate(value, { max }) {
    return value.length <= max;
  },
  message: 'The {_field_} field must be at most {max} characters',
});

extend('domain', {
  validate(value) { return validationFunctions.validateDomain(value); },
  message: 'The {_field_} field should be a valid url',
});

extend('ipv4', {
  validate(value) { return validationFunctions.validateIpAddress(value); },
  message: 'This should be a valid ip address',
});

extend('is_not_one_of', {
  validate(value, { items }) {
    if (typeof items === 'string') return !(items === value);
    return !items.includes(value);
  },
  params: ['items'],
  message: 'This {_field_} is currently being used',
});

// extend('url', {
//   validate(value = '') { return isUrl(value, { require_protocol: true }); },
//   message: 'Not a valid URL. It should be of form http(s)://example.com',
// });

extend('url_no_protocol', {
  validate(value) { return validationFunctions.validateUrlWithoutProtocol(value); },
  message: 'Please don\'t include protocol (http:// or https://)',
});

extend('is_not_one_of_domains', {
  validate(value, { items }) {
    if (typeof items === 'string') return !(items === value.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0]);
    return !items.includes(value.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0]);
  },
  params: ['items'],
  message: 'This Domain is currently being used',
});

extend('url_no_subdomain', {
  validate(value) { return !(value.split('.').length > 2); },
  message: 'Please don\'t include subdomain only root domain e.g. yourdomain.com',
});

extend('domain_status', {
  validate(value, { items }) { return !items.includes(String(value)); },
  params: ['items'],
  message: 'This Domain isn\'t activated yet',
});

extend('slash_start', {
  validate(value) { return !value.startsWith('/'); },
  message: 'Path shouldn\'t start with "/"',
});

extend('outgoing_link', {
  validate(value) { return validationFunctions.validateOutgoingLink(value); },
  message: 'Enter a valid url (include protocol http or https)',
});

extend('subdomain_name', {
  validate(value) { return validationFunctions.validateSubdomainName(value); },
  message: 'Special characters allowed: _ (underscore)',
});
