const defaultLanderAnalytics = {
  clicks: 0,
  conversions: 0,
  counter: 0,
  likelyBotVisits: 0,
  uniqueClicks: 0,
  uniqueVisits: 0,
  visits: 0,
  cvr: 0,
  leads: 0,
};

export { defaultLanderAnalytics };
