<template>
  <div :class="`ll-skeleton__card ${config.direction}`">
    <!-- Top Rows -->
    <div v-if="config.rows.start" class="ll-skeleton__rows ll-skeleton__rows--start">
      <div v-for="(_, index) in config.rows.start" :key="index" class="ll-skeleton__rows__row" />
    </div>

    <!-- Shape -->
    <div v-if="config.shape" :class="`ll-skeleton__shape ${config.shape.type} ${config.shape.position}`" :style="config.shape.style" />

    <!-- Bottom Rows -->
    <div v-if="config.rows.end" class="ll-skeleton__rows ll-skeleton__rows--end">
      <div v-for="(_, index) in config.rows.end" :key="index" class="ll-skeleton__rows__row" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardSkeleton',
  props: {
    config: {
      type: Object,
      default: () => ({
        direction: 'vertical',
        rows: {
          start: 2,
          end: 0,
        },
        shape: {
          type: 'square',
          position: 'start',
        },
      }),
    },
  },
};
</script>

<style lang="scss">
  .ll-skeleton__card {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 1.8rem;

    &.horizontal {
      flex-direction: row;
      align-items: center;

      .ll-skeleton__rows {
        flex: 1;
      }
    }

    .ll-skeleton__rows {
      display: flex;
      flex-direction: column;
    }

    .ll-skeleton__shape {
      background: linear-gradient(90deg,#f0f0f8 25%,#f4f4fb 37%,#f2f2f9 63%);
      background-size: 400% 100%;
      border-radius: .5rem;
      width: 100%;
      flex: 1;

      &.start {
        order: -1;
        width: 7.5rem;
        height: 5.2rem;
        min-width: 5.2rem;
        flex: unset;
      }

      &.circle {
        border-radius: 50%;
        width: 5.2rem;
      }
    }
  }

  .ll-skeleton {
    &.animated {
      .ll-skeleton__shape {
        animation: lander-skeleton .8s ease infinite;
      }
    }
  }
</style>
