const components = {
  // Form
  'collapse-cmp': `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 10H16V5" stroke="#181822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M23 3L16 10" stroke="#181822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9 3H5V21H23V17" stroke="#181822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`,
  'default-cmp': `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 10.9688C15.2437 10.9688 17.0625 9.14991 17.0625 6.90625C17.0625 4.66259 15.2437 2.84375 13 2.84375C10.7563 2.84375 8.9375 4.66259 8.9375 6.90625C8.9375 9.14991 10.7563 10.9688 13 10.9688Z" stroke="#181822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M19.0938 21.5312C21.3374 21.5312 23.1562 19.7124 23.1562 17.4688C23.1562 15.2251 21.3374 13.4062 19.0938 13.4062C16.8501 13.4062 15.0312 15.2251 15.0312 17.4688C15.0312 19.7124 16.8501 21.5312 19.0938 21.5312Z" stroke="#181822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6.90625 21.5312C9.14991 21.5312 10.9688 19.7124 10.9688 17.4688C10.9688 15.2251 9.14991 13.4062 6.90625 13.4062C4.66259 13.4062 2.84375 15.2251 2.84375 17.4688C2.84375 19.7124 4.66259 21.5312 6.90625 21.5312Z" stroke="#181822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
 `,
  'countdown-cmp': `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_8105_3525)">
      <path d="M4.24988 14.25C4.24988 15.3991 4.4762 16.5369 4.91593 17.5985C5.35566 18.6601 6.00018 19.6247 6.8127 20.4372C7.62521 21.2497 8.58981 21.8943 9.65141 22.334C10.713 22.7737 11.8508 23 12.9999 23C14.149 23 15.2868 22.7737 16.3484 22.334C17.41 21.8943 18.3746 21.2497 19.1871 20.4372C19.9996 19.6247 20.6441 18.6601 21.0839 17.5985C21.5236 16.5369 21.7499 15.3991 21.7499 14.25C21.7499 13.101 21.5236 11.9631 21.0839 10.9015C20.6441 9.83993 19.9996 8.87534 19.1871 8.06282C18.3746 7.25031 17.41 6.60579 16.3484 6.16606C15.2868 5.72633 14.149 5.5 12.9999 5.5C11.8508 5.5 10.713 5.72633 9.65141 6.16606C8.58981 6.60579 7.62521 7.25031 6.8127 8.06282C6.00018 8.87534 5.35566 9.83993 4.91593 10.9015C4.4762 11.9631 4.24988 13.101 4.24988 14.25Z" stroke="#181822" stroke-width="1.73077" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.9999 10.5V14.25H15.4999" stroke="#181822" stroke-width="1.73077" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6.74995 3L3.31244 5.50001" stroke="#181822" stroke-width="1.73077" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M19.2499 3L22.6874 5.50001" stroke="#181822" stroke-width="1.73077" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
      <clipPath id="clip0_8105_3525">
      <rect width="26" height="26" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  `,
  'newsletter-cmp': `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.25 9.75V20.3125C3.25 20.528 3.3356 20.7347 3.48798 20.887C3.64035 21.0394 3.84701 21.125 4.0625 21.125H21.9375C22.153 21.125 22.3597 21.0394 22.512 20.887C22.6644 20.7347 22.75 20.528 22.75 20.3125V9.75L13 3.25L3.25 9.75Z" stroke="#181822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.2273 15.4375L3.50055 20.8954" stroke="#181822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M22.4996 20.8954L14.7727 15.4375" stroke="#181822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M22.5 10L14.5 15.5H11L3.5 10" stroke="#181822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`,
  'socials-cmp': `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 23C18.5228 23 23 18.5228 23 13C23 7.47715 18.5228 3 13 3C7.47715 3 3 7.47715 3 13C3 18.5228 7.47715 23 13 23Z" stroke="#181822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17.1667 8.8335H15.5C14.837 8.8335 14.2011 9.09689 13.7322 9.56573C13.2634 10.0346 13 10.6705 13 11.3335V23.0002" stroke="#181822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9.66669 14.6667H16.3334" stroke="#181822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`,
  'footer-cmp': `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3" y="3" width="20" height="20" rx="2" stroke="#181822" stroke-width="1.5"/>
      <path d="M3 9H23V5C23 3.89543 22.1046 3 21 3H5C3.89543 3 3 3.89543 3 5V9Z" fill="#181822" stroke="#181822" stroke-width="1.5"/>
    </svg>`,
  'rating-cmp': `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_8109_3686)">
      <path d="M13.4651 18.9624C13.1736 18.8088 12.8252 18.8086 12.5335 18.962L8.61235 21.0236C7.87872 21.4093 7.02125 20.7863 7.16139 19.9694L7.91048 15.6025C7.96617 15.2779 7.85842 14.9466 7.62239 14.7169L4.44632 11.6253C3.85205 11.0468 4.17965 10.038 5.00041 9.91907L9.38157 9.28412C9.70743 9.23689 9.98916 9.03228 10.1349 8.73702L12.0957 4.76392C12.4625 4.02067 13.5224 4.02066 13.8892 4.76392L15.85 8.73702C15.9957 9.03228 16.2774 9.23689 16.6033 9.28412L20.9845 9.91907C21.8052 10.038 22.1328 11.0468 21.5385 11.6253L18.3625 14.7169C18.1264 14.9466 18.0187 15.2779 18.0744 15.6025L18.8231 19.967C18.9633 20.7844 18.1049 21.4074 17.3713 21.0208L13.4651 18.9624Z" stroke="#181822" stroke-width="1.5021" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
      <clipPath id="clip0_8109_3686">
      <rect width="26" height="26" fill="white"/>
      </clipPath>
      </defs>
    </svg>`,
  'llbutton-cmp': `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3" y="3" width="20" height="20" rx="2" fill="#181822"/>
      <rect x="5.25" y="5.25" width="15.5" height="15.5" rx="1.75" fill="#181822" stroke="white" stroke-width="1.5"/>
    </svg>`,
  'section-cmp': `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4.74994" y="9.20459" width="16.6818" height="7.59091" rx="1.06818" stroke="#181822" stroke-width="1.5"/>
      <path d="M20.3636 5.88636H5.81818C5.22824 5.88636 4.75 5.40812 4.75 4.81818C4.75 4.22824 5.22824 3.75 5.81818 3.75H20.3636C20.9536 3.75 21.4318 4.22824 21.4318 4.81818C21.4318 5.40812 20.9536 5.88636 20.3636 5.88636Z" fill="#181822" stroke="#181822" stroke-width="1.5"/>
      <path d="M20.3636 20.1136H5.81812C5.22818 20.1136 4.74994 20.5919 4.74994 21.1818C4.74994 21.7718 5.22818 22.25 5.81812 22.25H20.3636C20.9535 22.25 21.4318 21.7718 21.4318 21.1818C21.4318 20.5919 20.9535 20.1136 20.3636 20.1136Z" fill="#181822" stroke="#181822" stroke-width="1.5"/>
    </svg>`,
  'container-cmp': `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3.99994" y="8.45459" width="18.1818" height="9.09091" rx="1.81818" fill="#181822"/>
      <path d="M4 3V4.81818C4 5.82234 4.81403 6.63636 5.81818 6.63636H20.3636C21.3678 6.63636 22.1818 5.82234 22.1818 4.81818V3" stroke="#181822" stroke-width="1.5"/>
      <path d="M3.99994 23V21.1818C3.99994 20.1777 4.81397 19.3636 5.81812 19.3636H20.3636C21.3677 19.3636 22.1818 20.1777 22.1818 21.1818V23" stroke="#181822" stroke-width="1.5"/>
    </svg>`,
  'input-cmp': `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.5 8H4C3.44772 8 3 8.44772 3 9V17C3 17.5523 3.44772 18 4 18H5.5M9 18H22C22.5523 18 23 17.5523 23 17V9C23 8.44772 22.5523 8 22 8H9" stroke="black" stroke-width="1.5" stroke-linejoin="round"/>
      <path d="M5 5H7.5M10 5H7.5M7.5 5V20.5M7.5 20.5H5M7.5 20.5H10" stroke="black" stroke-width="1.5" stroke-linecap="round"/>
    </svg>`,
  'modal-cmp': `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="6.75" y="6.75" width="12.5" height="12.5" rx="1.25" stroke="#181822" stroke-width="1.5"/>
      <rect x="3" y="3" width="20" height="20" rx="2" stroke="#181822" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="4 4"/>
      <path d="M14.5 11.5L17 9M14.5 9L17 11.5" stroke="black" stroke-linecap="round"/>
    </svg>`,
  'navbar-cmp': `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="13" cy="13" r="10" stroke="black" stroke-width="1.5"/>
      <circle cx="7.75" cy="9.63159" r="0.75" fill="#181822"/>
      <path d="M10.1 9.63159H19.1" stroke="#181822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <circle cx="7.75" cy="13" r="0.75" fill="#181822"/>
      <path d="M10.1 13H19.1" stroke="#181822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <circle cx="7.75" cy="16.3685" r="0.75" fill="#181822"/>
      <path d="M10.1 16.3685H19.1" stroke="#181822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`,
  'link-cmp': `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.3664 6.66316L14.5842 4.44527C15.0419 3.98723 15.5852 3.62383 16.1833 3.37585C16.7814 3.12787 17.4225 3.00015 18.07 3C18.7174 2.99985 19.3586 3.12727 19.9568 3.37497C20.555 3.62268 21.0986 3.98582 21.5564 4.44364C22.0142 4.90147 22.3774 5.44502 22.6251 6.04323C22.8728 6.64144 23.0002 7.28259 23 7.93005C22.9999 8.57752 22.8722 9.21861 22.6242 9.81671C22.3762 10.4148 22.0128 10.9582 21.5548 11.4158L18.3863 14.5842C17.9287 15.0419 17.3853 15.405 16.7873 15.6527C16.1893 15.9004 15.5484 16.0279 14.9011 16.0279C14.2538 16.0279 13.6129 15.9004 13.0149 15.6527C12.4169 15.405 11.8735 15.0419 11.4158 14.5842" stroke="#32313E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.6337 19.3369L11.4158 21.5548C10.9582 22.0128 10.4148 22.3762 9.8167 22.6242C9.2186 22.8722 8.57751 22.9999 7.93005 23C7.28258 23.0002 6.64143 22.8728 6.04322 22.6251C5.44501 22.3774 4.90147 22.0142 4.44364 21.5564C3.98581 21.0986 3.62268 20.555 3.37497 19.9568C3.12727 19.3586 2.99985 18.7174 3 18.07C3.00015 17.4225 3.12787 16.7814 3.37585 16.1833C3.62383 15.5852 3.98722 15.0418 4.44526 14.5842L7.61368 11.4158C8.07137 10.9581 8.61473 10.5951 9.21273 10.3474C9.81073 10.0997 10.4517 9.97217 11.0989 9.97217C11.7462 9.97217 12.3871 10.0997 12.9851 10.3474C13.5831 10.5951 14.1265 10.9581 14.5842 11.4158" stroke="#32313E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`,
  'video-cmp': `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 23C18.5228 23 23 18.5228 23 13C23 7.47715 18.5228 3 13 3C7.47715 3 3 7.47715 3 13C3 18.5228 7.47715 23 13 23Z" stroke="#181822" stroke-width="1.5" stroke-miterlimit="10"/>
      <path d="M10.9487 16.3333V9.66663L16.0128 13L10.9487 16.3333Z" fill="#181822" stroke="#181822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`,
  'text-cmp': `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.9994 5.68701V20.312" stroke="#181822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4.87439 8.93701V5.68701H21.1244V8.93701" stroke="#181822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9.74939 20.312H16.2494" stroke="#181822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`,
  'div-cmp': `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5 3C3.89543 3 3 3.89543 3 5V21C3 22.1046 3.89543 23 5 23H21C22.1046 23 23 22.1046 23 21V5C23 3.89543 22.1046 3 21 3H5ZM6 5C5.44772 5 5 5.44772 5 6V20C5 20.5523 5.44772 21 6 21H20C20.5523 21 21 20.5523 21 20V6C21 5.44772 20.5523 5 20 5H6Z" fill="#040404"/>
    </svg>`,
  'grid-cell-cmp': `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5 5C15.5 4.29993 15.5 3.9499 15.6362 3.68251C15.7561 3.44731 15.9473 3.25608 16.1825 3.13624C16.4499 3 16.7999 3 17.5 3H21C21.7001 3 22.0501 3 22.3175 3.13624C22.5527 3.25608 22.7439 3.44731 22.8638 3.68251C23 3.9499 23 4.29993 23 5V8.5C23 9.20007 23 9.5501 22.8638 9.81749C22.7439 10.0527 22.5527 10.2439 22.3175 10.3638C22.0501 10.5 21.7001 10.5 21 10.5H17.5C16.7999 10.5 16.4499 10.5 16.1825 10.3638C15.9473 10.2439 15.7561 10.0527 15.6362 9.81749C15.5 9.5501 15.5 9.20007 15.5 8.5V5Z" stroke="#181822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3 17.5C3 16.7999 3 16.4499 3.13624 16.1825C3.25608 15.9473 3.44731 15.7561 3.68251 15.6362C3.9499 15.5 4.29993 15.5 5 15.5H8.5C9.20007 15.5 9.5501 15.5 9.81749 15.6362C10.0527 15.7561 10.2439 15.9473 10.3638 16.1825C10.5 16.4499 10.5 16.7999 10.5 17.5V21C10.5 21.7001 10.5 22.0501 10.3638 22.3175C10.2439 22.5527 10.0527 22.7439 9.81749 22.8638C9.5501 23 9.20007 23 8.5 23H5C4.29993 23 3.9499 23 3.68251 22.8638C3.44731 22.7439 3.25608 22.5527 3.13624 22.3175C3 22.0501 3 21.7001 3 21V17.5Z" stroke="#181822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15.5 17.5C15.5 16.7999 15.5 16.4499 15.6362 16.1825C15.7561 15.9473 15.9473 15.7561 16.1825 15.6362C16.4499 15.5 16.7999 15.5 17.5 15.5H21C21.7001 15.5 22.0501 15.5 22.3175 15.6362C22.5527 15.7561 22.7439 15.9473 22.8638 16.1825C23 16.4499 23 16.7999 23 17.5V21C23 21.7001 23 22.0501 22.8638 22.3175C22.7439 22.5527 22.5527 22.7439 22.3175 22.8638C22.0501 23 21.7001 23 21 23H17.5C16.7999 23 16.4499 23 16.1825 22.8638C15.9473 22.7439 15.7561 22.5527 15.6362 22.3175C15.5 22.0501 15.5 21.7001 15.5 21V17.5Z" stroke="#181822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3 5C3 4.29993 3 3.9499 3.13624 3.68251C3.25608 3.44731 3.44731 3.25608 3.68251 3.13624C3.9499 3 4.29993 3 5 3H8.5C9.20007 3 9.5501 3 9.81749 3.13624C10.0527 3.25608 10.2439 3.44731 10.3638 3.68251C10.5 3.9499 10.5 4.29993 10.5 5V8.5C10.5 9.20007 10.5 9.5501 10.3638 9.81749C10.2439 10.0527 10.0527 10.2439 9.81749 10.3638C9.5501 10.5 9.20007 10.5 8.5 10.5H5C4.29993 10.5 3.9499 10.5 3.68251 10.3638C3.44731 10.2439 3.25608 10.0527 3.13624 9.81749C3 9.5501 3 9.20007 3 8.5V5Z" fill="#181822" stroke="#181822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`,
  'grid-cmp': `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5 5C15.5 4.29993 15.5 3.9499 15.6362 3.68251C15.7561 3.44731 15.9473 3.25608 16.1825 3.13624C16.4499 3 16.7999 3 17.5 3H21C21.7001 3 22.0501 3 22.3175 3.13624C22.5527 3.25608 22.7439 3.44731 22.8638 3.68251C23 3.9499 23 4.29993 23 5V8.5C23 9.20007 23 9.5501 22.8638 9.81749C22.7439 10.0527 22.5527 10.2439 22.3175 10.3638C22.0501 10.5 21.7001 10.5 21 10.5H17.5C16.7999 10.5 16.4499 10.5 16.1825 10.3638C15.9473 10.2439 15.7561 10.0527 15.6362 9.81749C15.5 9.5501 15.5 9.20007 15.5 8.5V5Z" stroke="#181822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3 5C3 4.29993 3 3.9499 3.13624 3.68251C3.25608 3.44731 3.44731 3.25608 3.68251 3.13624C3.9499 3 4.29993 3 5 3H8.5C9.20007 3 9.5501 3 9.81749 3.13624C10.0527 3.25608 10.2439 3.44731 10.3638 3.68251C10.5 3.9499 10.5 4.29993 10.5 5V8.5C10.5 9.20007 10.5 9.5501 10.3638 9.81749C10.2439 10.0527 10.0527 10.2439 9.81749 10.3638C9.5501 10.5 9.20007 10.5 8.5 10.5H5C4.29993 10.5 3.9499 10.5 3.68251 10.3638C3.44731 10.2439 3.25608 10.0527 3.13624 9.81749C3 9.5501 3 9.20007 3 8.5V5Z" stroke="#181822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3 17.5C3 16.7999 3 16.4499 3.13624 16.1825C3.25608 15.9473 3.44731 15.7561 3.68251 15.6362C3.9499 15.5 4.29993 15.5 5 15.5H8.5C9.20007 15.5 9.5501 15.5 9.81749 15.6362C10.0527 15.7561 10.2439 15.9473 10.3638 16.1825C10.5 16.4499 10.5 16.7999 10.5 17.5V21C10.5 21.7001 10.5 22.0501 10.3638 22.3175C10.2439 22.5527 10.0527 22.7439 9.81749 22.8638C9.5501 23 9.20007 23 8.5 23H5C4.29993 23 3.9499 23 3.68251 22.8638C3.44731 22.7439 3.25608 22.5527 3.13624 22.3175C3 22.0501 3 21.7001 3 21V17.5Z" stroke="#181822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15.5 17.5C15.5 16.7999 15.5 16.4499 15.6362 16.1825C15.7561 15.9473 15.9473 15.7561 16.1825 15.6362C16.4499 15.5 16.7999 15.5 17.5 15.5H21C21.7001 15.5 22.0501 15.5 22.3175 15.6362C22.5527 15.7561 22.7439 15.9473 22.8638 16.1825C23 16.4499 23 16.7999 23 17.5V21C23 21.7001 23 22.0501 22.8638 22.3175C22.7439 22.5527 22.5527 22.7439 22.3175 22.8638C22.0501 23 21.7001 23 21 23H17.5C16.7999 23 16.4499 23 16.1825 22.8638C15.9473 22.7439 15.7561 22.5527 15.6362 22.3175C15.5 22.0501 15.5 21.7001 15.5 21V17.5Z" stroke="#181822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`,
  'heading-cmp': `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2714_1960)">
      <path d="M7 5V23M19 14H8M19 5V23M5 23H9M5 5H9M17 23H21M17 5H21" stroke="#181822" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
      </g>
      <defs>
      <clipPath id="clip0_2714_1960">
      <rect width="26" height="26" fill="white"/>
      </clipPath>
      </defs>
    </svg>`,
  'icon-cmp': `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3" y="3" width="8" height="8" rx="2" stroke="#181822" stroke-width="1.5"/>
      <rect x="15" y="15" width="8" height="8" stroke="#181822" stroke-width="1.5"/>
      <rect x="15.1053" y="3" width="8" height="8" rx="4" stroke="#181822" stroke-width="1.5"/>
      <path d="M7 15L11 23H3L7 15Z" stroke="#181822" stroke-width="1.5"/>
    </svg>`,
  'svg-cmp': `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3" y="3" width="8" height="8" rx="2" stroke="#181822" stroke-width="1.5"/>
      <rect x="15" y="15" width="8" height="8" stroke="#181822" stroke-width="1.5"/>
      <rect x="15.1053" y="3" width="8" height="8" rx="4" stroke="#181822" stroke-width="1.5"/>
      <path d="M7 15L11 23H3L7 15Z" stroke="#181822" stroke-width="1.5"/>
    </svg>`,
  'image-cmp': `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.8298 17.9219L13.7448 15.8542C12.7386 14.8564 12.2355 14.3575 11.6582 14.1752C11.1505 14.0147 10.6048 14.0206 10.1006 14.192C9.52735 14.3868 9.03518 14.8965 8.05085 15.9158L3.05512 20.8502M15.8298 17.9219L16.2566 17.4987C17.264 16.4997 17.7676 16.0002 18.3456 15.8179C18.8538 15.6576 19.4 15.6638 19.9045 15.8358C20.478 16.0314 20.9701 16.5423 21.9543 17.5642L23 18.6168M15.8298 17.9219L20.8437 22.9457M20.8437 22.9457C20.397 23 19.8179 23 19 23H7C5.59987 23 4.8998 23 4.36502 22.7275C3.89462 22.4878 3.51217 22.1054 3.27248 21.635C3.15995 21.4141 3.0939 21.1651 3.05512 20.8502M20.8437 22.9457C21.1617 22.907 21.4126 22.8408 21.635 22.7275C22.1054 22.4878 22.4878 22.1054 22.7275 21.635C23 21.1002 23 20.4001 23 19V18.6168M3.05512 20.8502C3 20.4026 3 19.8219 3 19V7C3 5.59987 3 4.8998 3.27248 4.36502C3.51217 3.89462 3.89462 3.51217 4.36502 3.27248C4.8998 3 5.59987 3 7 3H19C20.4001 3 21.1002 3 21.635 3.27248C22.1054 3.51217 22.4878 3.89462 22.7275 4.36502C23 4.8998 23 5.59987 23 7V18.6168M19.25 9.24987C19.25 10.6306 18.1307 11.7499 16.75 11.7499C15.3693 11.7499 14.25 10.6306 14.25 9.24987C14.25 7.86915 15.3693 6.74987 16.75 6.74987C18.1307 6.74987 19.25 7.86915 19.25 9.24987Z" stroke="#181822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`
};

export default components;
