import moment from 'moment';

const dashboardStatsChart = {
  chart: {
    height: 200,
    type: 'area',
    toolbar: {
      show: false,
    },
    sparkline: {
      enabled: true,
    },
  },
  colors: ['#266bf5', '#80bcff'],
  dataLabels: {
    enabled: false,
  },
  legend: {
    // show: true,
  },
  fill: {
    opacity: 0.1,
    type: 'gradient',
    gradient: {
      shade: 'light',
      shadeIntensity: 0.1,
      opacityFrom: 0.35,
      opacityTo: 0.05,
    },
  },
  grid: {
    show: true,
    borderColor: '#f1f1ff',
  },
  stroke: {
    curve: 'straight',
    width: 1.5,
    dashArray: [0, 3],
  },
  xaxis: {
    categories: [],
    labels: {
      show: false,
      datetimeUTC: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
      formatter: (value) => value.toFixed(0)
    }
  },
};

const landerAnalyticsChart = (type = 'line') => ({
  chart: {
    type,
    height: 400,
    stacked: type === 'bar',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '55%',
      endingShape: 'rounded',
    },
  },
  colors: [
    '#80bcff',
    'rgba(96,91,255,.7)',
    'rgba(96,200,32,.7)',
    'rgba(255,191,28,.7)',
    '#FF6633',
    '#FF33FF',
    '#FFB399',
    '#E6B333',
    '#3366E6',
    '#999966',
    '#809980',
    '#E6FF80',
    '#1AFF33',
    '#999933',
    '#FF3380',
    '#CCCC00',
    '#66E64D',
    '#4D80CC',
    '#FF4D4D',
    '#99E6E6',
    '#6666FF'],
  dataLabels: {
    enabled: false,
  },
  legend: {
    position: 'bottom',
    fontSize: '15px',
    markers: {
      width: 16,
      height: 16,
      radius: 0,
    },
    itemMargin: {
      horizontal: 12,
    },
  },
  grid: {
    show: true,
    borderColor: '#f1f1ff',
  },
  stroke: {
    curve: 'straight',
    show: true,
    width: type === 'area' ? 1.5 : 2,
    colors: type === 'bar' ? ['transparent'] : undefined,
  },
  xaxis: {
    type: 'datetime',
    categories: [],
    labels: {
      datetimeUTC: false,
    },
  },
  yaxis: {
    labels: {
      formatter: (value) => {
        if (value) return value.toFixed(0);
        return value;
      }
    }
  },
  fill: {
    opacity: 1,
  },
});

const chartTooltip = (payload, { metric, categories }) => {
  const { series, dataPointIndex, w } = payload;

  const ctg = categories || w.config.xaxis.categories;
  const date = ctg[dataPointIndex];
  const isByHour = date.includes(':');
  const fromDate = moment(date).format(isByHour ? 'MMM D, HH:mm' : 'MMM D, YYYY');
  const toDate = moment(date).add(1, 'hours').format('HH:mm');
  const formatedDate = `${fromDate} ${isByHour ? ` - ${toDate}` : ''}`;

  const values = series.map((item) => item[dataPointIndex]);
  return `
    <div class="ll-chart-tooltip">
      <div class="ll-chart-tooltip__header">
        <span class="ll-chart-tooltip__header__current-date">${formatedDate}</span>
        <span class="ll-chart-tooltip__header__metric">${metric}<span>
      </div>
      <div class="ll-chart-tooltip__body">
        ${values.map((item, index) => `
          ${item !== undefined ? `<div class="ll-chart-tooltip__body__item">
          <div class="ll-tooltip-item-name">
            <span class="ll-tooltip-item-circle" style="background: ${w.config.colors[index]}"></span>
            <span class="ll-tooltip-item-text">${w.config.series[index].name}</span>
          </div>
          <span class="ll-tooltip-item-value">${item}</span>
        </div>` : ''}
        `).join('')}
      </div>
    </div>`;
};

export {
  dashboardStatsChart,
  landerAnalyticsChart,
  chartTooltip
};
